<template>
  <b-container fluid id="savings-explorer">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <h1>Savings Explorer 🚀</h1>

    <div class="d-none d-md-flex justify-content-end">
      <a
        target="_blank"
        href="https://apps.xero.com/uk/app/reducer/reviews"
        class="xero-review reducer-box-shadow px-3 py-2"
      >
        <p class="widescreen mr-3 my-auto">
          Review us on <br /><b>Xero</b>
          <font-awesome-icon class="ml-1" :icon="['fas', 'chevron-right']" />
        </p>

        <img border="0" height="55px" width="55px" src="@/assets/images/xero.png" />
      </a>
    </div>

    <Busy v-if="busy && !summaries && !filterOn" primary :size="3" class="mt-4" />

    <div v-else-if="!busy && !filterOn && (!summaries || summaries.length == 0)">
      <p>There is no savings data available.</p>
    </div>

    <div v-else>
      <div v-if="isAdmin" class="mb-1 text-right">
        <b-button @click="showCommission = !showCommission"
          >{{ showCommission ? "Hide" : " Show" }} details</b-button
        >
      </div>
      <savings-explorer-overview :total="total" :summaries="summaries" :admin="showCommission" />
      <savings-explorer-table
        :admin="showCommission"
        :summaries="summaries"
        :total="total"
        ref="savingsTable"
      >
        <template v-slot:filter>
          <b-form-group label-for="savSummaryPublishedStart" label="PUBLISHED FROM">
            <b-form-input
              class="reducer-input"
              id="savSummaryPublishedStart"
              v-model="reportPublishedStart"
              type="date"
            />
          </b-form-group>
          <b-form-group label-for="savSummaryPublishedEnd" label="PUBLISHED TO">
            <b-form-input
              class="reducer-input"
              id="savSummaryPublishedEnd"
              v-model="reportPublishedEnd"
              type="date"
            />
          </b-form-group>
          <b-button
            variant="primary"
            class="filter-btn reducer-btn"
            @click="fetchData"
            :disabled="busy"
          >
            <Busy light v-if="busy" :size="1.5" :margin="0" />
            <span v-else>Filter</span>
          </b-button>
          <b-button class="ml-2 filter-btn" :disabled="busy" @click="clearFilter">
            <span>Clear</span>
          </b-button>
        </template>
      </savings-explorer-table>
    </div>
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import Console from "@/console";
import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";
import FormatHelper from "@/helper/formathelper";
import SavingsExplorerTable from "@/components/SavingsExplorerTable";
import SavingsExplorerOverview from "@/components/SavingsExplorerOverview";

export default {
  name: "SavingsExplorer",
  components: {
    Busy,
    SavingsExplorerTable,
    SavingsExplorerOverview
  },
  data() {
    return {
      title: "Savings Explorer",
      isAdmin: false,
      showCommission: false,
      busy: true,
      userId: null,
      summaries: null,
      total: null,
      reportPublishedStart: null,
      reportPublishedEnd: null
    };
  },
  async created() {
    if (this.$route.params.userId) {
      this.userId = this.$route.params.userId;
    } else {
      var token = await CognitoAuth.getCurrentUserAccessToken();
      this.userId = TokenHelper.parseJwtToken(token).sub;
    }
    this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
    this.fetchData();
  },
  computed: {
    filterOn: function () {
      return this.reportPublishedStart != null || this.reportPublishedEnd != null;
    }
  },
  methods: {
    async fetchData() {
      this.busy = true;
      let client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}savings/get/summary`, {
          userIds: [this.userId],
          reportPublishedStart: FormatHelper.formatDateToLocalDateTime(this.reportPublishedStart),
          reportPublishedEnd: FormatHelper.formatDateToLocalDateTime(this.reportPublishedEnd, false)
        })
        .then((response) => {
          Console.log("Savings response: ", response);
          this.summaries = response.data.savingSummaries;
          this.total = response.data.total;
          this.total.organisationsCount = response.data.organisationsCount;
        })
        .catch((e) => {
          Console.error(e);
          this.showWarning("There was a problem fetching savings data.", "warning");
        })
        .finally(() => (this.busy = false));
    },
    clearFilter() {
      let datesNotNull = this.reportPublishedStart || this.reportPublishedEnd;
      this.$refs.savingsTable._data.orgNameSearch = "";
      if (datesNotNull) {
        this.busy = true;
        this.reportPublishedStart = null;
        this.reportPublishedEnd = null;
        this.fetchData();
      }
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/common.scss";

#savings-explorer {
  .xero-review {
    position: absolute;
    top: 85px;
    right: 15px;

    display: flex;
    align-items: center;
    background-color: rgba($color: #13b5ea, $alpha: 0.9);
    text-decoration: none;
    border-radius: 12px;

    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }

    & > img {
      border-radius: 50%;
      border: 2px solid white;
    }

    & > p {
      color: white;
    }

    @media screen and (max-width: 767px) {
      position: static;
      margin-bottom: 1em;

      &:hover {
        transform: none;
      }
    }
  }

  .filter-group {
    display: flex;
    align-items: flex-end !important;
    .form-group {
      margin-bottom: 0;
      width: 15rem;
      margin-right: 1rem;
      label {
        font-size: 0.8rem;
        text-transform: capitalize;
      }
    }
    .filter-btn {
      height: 2.2rem;
      width: 4rem;
    }
  }
  @media screen and (max-width: 500px) {
    h1 {
      font-size: 2rem;
    }
  }
}
</style>
